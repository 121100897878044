<template>
  <v-app>
    <v-main>
      <div v-if="currentRoute === '/admin'"><GroupFinderAdmin /></div>
      <div v-else-if="currentRoute === '/'"><GroupFinder /></div>
      <div v-else>
        <v-row class="justify-center mx-2 mb-2">
          <p>Page not found</p>
        </v-row>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import GroupFinder from "./components/GroupFinder";
import GroupFinderAdmin from "./components/GroupFinderAdmin";

export default {
  name: "App",

  components: {
    GroupFinder,
    GroupFinderAdmin,
  },

  data() {
    return {
      currentRoute: window.location.pathname,
    };
  },
};
</script>
