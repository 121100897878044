import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import {VueReCaptcha} from "vue-recaptcha-v3";

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueReCaptcha, {
  siteKey:"6Lf8RPUZAAAAADTsUjVVIvtQkIM5mJKou-jirUAE",
  loaderOptions : {
    autoHideBadge: true
  }
})