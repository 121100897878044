<template>
  <div class="pa-2">
    <v-data-table
      :headers="headers"
      :items="filteredCoreGroups"
      :items-per-page="10"
      item-key="name"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-row class="pa-2">
          <v-col cols="6">
            <v-combobox
              v-model="meetingDayFilter"
              :items="meetingDayOptions"
              :label="meetingDayLabel"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="meetingTimeFilter"
              :items="meetingTimeOptions"
              :label="meetingTimeLabel"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="genderFilter"
              :items="genderOptions"
              :label="genderLabel"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="languageFilter"
              :items="languageOptions"
              :label="languageLabel"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="pa-2">
          <v-col cols="12">
            <v-text-field v-model="search" :label="searchLabel"> </v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-end mx-2 mb-2">
          <v-btn
            depressed
            color="primary"
            @click="clearFilters()"
            :disabled="disabledButton"
          >
            {{ clearFiltersLabel }}
            <v-icon small color="white" class="mx-1">
              mdi-filter-remove
            </v-icon>
          </v-btn>
        </v-row>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline mx-2">{{ contactUsLabel }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="joinItem.firstName"
                        :label="firstNameLabel"
                        :rules="[(v) => !!v || `${itemRequiredLabel}`]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="joinItem.lastName"
                        :label="lastNameLabel"
                        :rules="[(v) => !!v || `${itemRequiredLabel}`]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="joinItem.message"
                        color="primary"
                        :label="messageLabel"
                        :rules="[(v) => !!v || `${itemRequiredLabel}`]"
                        required
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="joinItem.email"
                        :label="emailLabel"
                        :rules="[(v) => !!v || `${itemRequiredLabel}`]"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">{{
                cancelLabel
              }}</v-btn>
              <v-btn color="blue darken-1" text @click="sendEmail">{{
                sendLabel
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.contactUs="{ item }">
        <v-btn depressed color="primary" @click="contactUs(item)">
          {{ contactUsLabel }}
          <v-icon small color="white" class="mx-2"> mdi-email </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.meetingTime="{ item }">
        {{ getLocalTime(item) }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ getDate(item.startDate) }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ getDate(item.endDate) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
/* eslint-disable no-debugger */

export default {
  name: "GroupFinder",

  data() {
    return {
      headers: [
        { text: "", value: "contactUs", sortable: false },
        { text: "Course", value: "course" },
        { text: "Language", value: "language.text" },
        { text: "Gender", value: "gender.text" },
        { text: "Meeting Day", value: "meetingDay.text" },
        { text: "Meeting Time", value: "meetingTime" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
      ],
      coreGroups: [],
      dialog: false,
      search: "",
      searchLabel: "Keyword search",
      meetingDayLabel: "Meeting Day",
      meetingTimeLabel: "Meeting time",
      genderLabel: "Gender",
      languageLabel: "Language",
      groupTypeLabel: "Group Type",
      firstNameLabel: "First Name",
      lastNameLabel: "Last Name",
      contactUsLabel: "Contact Us",
      messageLabel: "Message",
      emailLabel: "Email",
      cancelLabel: "Cancel",
      sendLabel: "Send",
      clearFiltersLabel: "Clear Filters",
      itemRequiredLabel: "Item is required",
      timeZoneFilter: "",
      timeZoneOptions: [
        "Europe/Zagreb (HR) CET UTC +01:00",
        "United Arab Emirates - Asia/Dubai (AE) +04 UTC +04:00",
      ],
      meetingDayFilter: { text: "All Days", value: "" },
      meetingDayOptions: [
        { text: "All Days", value: "" },
        { text: "Sunday", value: "Sunday" },
        { text: "Monday", value: "Monday" },
        { text: "Tuesday", value: "Tuesday" },
        { text: "Wednesday", value: "Wednesday" },
        { text: "Thursday", value: "Thursday" },
        { text: "Friday", value: "Friday" },
        { text: "Saturday", value: "Saturday" },
      ],
      meetingTimeFilter: { text: "All Times", value: "" },
      meetingTimeOptions: [
        { text: "All Times", value: "" },
        { text: "Morning (6 am to noon)", value: "06:00-12:00" },
        { text: "Afternoon (noon to 6 pm)", value: "12:00-18:00" },
        { text: "Evening (6 pm to Midnight)", value: "18:00-24:00" },
        { text: "Night (Minnight to 6 am)", value: "00:00-06:00" },
      ],
      genderFilter: { text: "All", value: "" },
      genderOptions: [
        { text: "All", value: "" },
        {
          text: "Mixed - both men and women",
          value: "Mixed - both men and women",
        },
        { text: "Women", value: "Women" },
        { text: "Men", value: "Men" },
      ],
      languageFilter: { text: "All", value: "" },
      languageOptions: [
        { text: "All", value: "" },
        {
          text: "American Sign Language (ASL)",
          value: "American Sign Language (ASL)",
        },
        { text: "Arabic", value: "Arabic" },
        { text: "Cantonese", value: "Cantonese" },
        { text: "Dutch", value: "Dutch" },
        { text: "English", value: "English" },
        { text: "Filipino", value: "Filipino" },
        { text: "German", value: "German" },
        { text: "Hindi", value: "Hindi" },
        { text: "Indonesian", value: "Indonesian" },
        { text: "Japanese", value: "Japanese" },
        { text: "Korean", value: "Korean" },
        { text: "Mandarin", value: "Mandarin" },
        { text: "Portuguese", value: "Portuguese" },
        { text: "Romanian", value: "Romanian" },
        { text: "Russian", value: "Russian" },
        { text: "Spanish", value: "Spanish" },
        { text: "Tamil", value: "Tamil" },
        { text: "Thai", value: "Thai" },
        { text: "Finnish", value: "Finnish" }
      ],
      selectedLanguageOption : '',

      joinIndex: -1,
      joinItem: {
        entryId: 0,
        course: "",
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        contact: "",
      },
      valid: false,
    };
  },
  computed: {
    filteredCoreGroups() {
      const meetingDaySearch = this.meetingDayFilter.value.trim();
      const meetingTimeSearch = this.meetingTimeFilter.value.trim();
      const genderSearch = this.genderFilter.value.trim();
      const languageSearch = this.languageFilter.value.trim();
      let filteredCoreGroups = this.coreGroups;

      if (meetingDaySearch !== "") {
        filteredCoreGroups = filteredCoreGroups.filter(
          (cg) => cg.meetingDay.value.indexOf(meetingDaySearch) > -1
        );
      }
      if (meetingTimeSearch !== "") {
        let startTime = meetingTimeSearch.substring(
          0,
          meetingTimeSearch.lastIndexOf("-")
        );
        let endTime = meetingTimeSearch.substring(
          meetingTimeSearch.lastIndexOf("-") + 1,
          meetingTimeSearch.length
        );
        filteredCoreGroups = filteredCoreGroups.filter((cg) => {
          return (
            this.calculateOffset(cg.meetingTime) >= startTime &&
            this.calculateOffset(cg.meetingTime) <= endTime
          );
        });
      }
      if (genderSearch !== "") {
        console.log('filtered core groups')
        console.log(filteredCoreGroups)
        filteredCoreGroups = filteredCoreGroups.filter(
          (cg) => cg.gender.value === genderSearch
        );
      }
      if (languageSearch !== "") {
        filteredCoreGroups = filteredCoreGroups.filter(
          (cg) => cg.language.value === languageSearch
        );
      }



      return filteredCoreGroups;
    },
    disabledButton() {
      const meetingDaySearch = this.meetingDayFilter.value.trim();
      const meetingTimeSearch = this.meetingTimeFilter.value.trim();
      const genderSearch = this.genderFilter.value.trim();
      const languageSearch = this.languageFilter.value.trim();
      if (
        this.search !== "" ||
        meetingDaySearch !== "" ||
        meetingTimeSearch !== "" ||
        genderSearch !== "" ||
        languageSearch !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
    let translatedMeetingDaysOptions = [];
    let translatedLanguageOptions = [];
    let translatedGenderOptions = [];
    let translatedMeetingTimeOptions = [];
    let translatedHeaders = [];
    let translateMeetingDayOptions = [];
    const languageNames = new Intl.DisplayNames(['en'], {type: 'language'});
    console.log(navigator.language);

    this.languageOptions.forEach((l) => {
      console.log(`text: ${l.text}`)
      console.log(`value: ${l.value}`)
    });

    this.meetingDayOptions.forEach((x) => {
      let tempObj = {
        text: x.text,
      };
      translatedMeetingDaysOptions.push(tempObj);
    });

    this.languageOptions.forEach((x) => {
      let tempObj = {
        text: x.text,
      };
      translatedLanguageOptions.push(tempObj);
    });

    this.genderOptions.forEach((x) => {
      let tempObj = {
        text: x.text,
      };
      translatedGenderOptions.push(tempObj);
    });

    this.meetingDayOptions.forEach(x => {
      let tempObj = {
        text: x.text
      };
      translatedMeetingDaysOptions.push(tempObj);
    });

    this.meetingTimeOptions.forEach((x) => {
      let tempObj = {
        text: x.text,
      };
      translatedMeetingTimeOptions.push(tempObj);
    });

    this.headers.forEach((x) => {
      let tempObj = {
        text: x.text,
      };
      translatedHeaders.push(tempObj);
    });
    console.log('navigator language')
    console.log(navigator.language)
    axios
      .post(
        "/api/cbsigroup/translate/" + navigator.language,
        translatedMeetingDaysOptions
      )
      .then((response) => {
        console.log('response1')
        console.log(response.data)
        let responseData = response.data;
        for (let i = 0; i < this.meetingDayOptions.length; i++) {
          this.meetingDayOptions[i].text = responseData[i].translations[0].text;
        }
        this.meetingDayFilter = this.meetingDayOptions[0];
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log('selected language')
    console.log(this.selectedLanguageOption)
    axios
      .post(
        "/api/cbsigroup/translate/"+ navigator.language + "/"+ this.selectedLanguageOption,
        translatedLanguageOptions
      )
      .then((response) => {
        let responseData = response.data;
        console.log('response2')
        console.log(response.data)
        for (let i = 0; i < this.languageOptions.length; i++) {
          this.languageOptions[i].text = responseData[i].translations[0].text;
          console.log(responseData[i].translations[0].text)
        }
        this.languageFilter = this.languageOptions[0];
        this.selectedLanguageOption = languageNames.of(navigator.language);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        "/api/cbsigroup/translate/" + navigator.language,
        translatedGenderOptions
      )
      .then((response) => {
        let responseData = response.data;
        for (let i = 0; i < this.genderOptions.length; i++) {
          this.genderOptions[i].text = responseData[i].translations[0].text;
        }
        this.genderFilter = this.genderOptions[0];
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
        .post(
            "/api/cbsigroup/translate/" + navigator.language,
            translateMeetingDayOptions
        )
        .then((response) => {
          let responseData = response.data;
          for (let i = 0; i < this.meetingDayOptions.length; i++) {
            this.meetingDayOptions[i].text = responseData[i].translations[0].text;
          }
          this.meetingDayFilter = this.meetingDayOptions[0];
        })
        .catch(function (error) {
          console.log(error);
        });


    axios
      .post(
        "/api/cbsigroup/translate/" +  navigator.language,
        translatedMeetingTimeOptions
      )
      .then((response) => {
        let responseData = response.data;
        for (let i = 0; i < this.meetingTimeOptions.length; i++) {
          this.meetingTimeOptions[i].text =
            responseData[i].translations[0].text;
        }
        this.meetingTimeFilter = this.meetingTimeOptions[0];
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post("/api/cbsigroup/translate/" + navigator.language, translatedHeaders)
      .then((response) => {
        let responseData = response.data;
        for (let i = 0; i < this.headers.length; i++) {
          this.headers[i].text = responseData[i].translations[0].text;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    let translatedLabels = [
      { text: this.searchLabel },
      { text: this.meetingDayLabel },
      { text: this.meetingTimeLabel },
      { text: this.genderLabel },
      { text: this.languageLabel },
      { text: this.groupTypeLabel },
      { text: this.firstNameLabel },
      { text: this.lastNameLabel },
      { text: this.contactUsLabel },
      { text: this.messageLabel },
      { text: this.emailLabel },
      { text: this.cancelLabel },
      { text: this.sendLabel },
      { text: this.clearFiltersLabel },
      { text: this.itemRequiredLabel }
    ];


    axios
      .post("/api/cbsigroup/translate/"+ navigator.language, translatedLabels)
      .then((response) => {
        let responseData = response.data;
        this.searchLabel = responseData[0].translations[0].text;
        this.meetingDayLabel = responseData[1].translations[0].text;
        this.meetingTimeLabel = responseData[2].translations[0].text;
        this.genderLabel = responseData[3].translations[0].text;
        this.languageLabel = responseData[4].translations[0].text;
        this.firstNameLabel = responseData[6].translations[0].text;
        this.lastNameLabel = responseData[7].translations[0].text;
        this.contactUsLabel = responseData[8].translations[0].text;
        this.messageLabel = responseData[9].translations[0].text;
        this.emailLabel = responseData[10].translations[0].text;
        this.cancelLabel = responseData[11].translations[0].text;
        this.sendLabel = responseData[12].translations[0].text;
        this.clearFiltersLabel = responseData[13].translations[0].text;
        this.itemRequiredLabel = responseData[14].translations[0].text;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    initialize() {
      axios
        .get("/api/cbsigroup/getAll", {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        })
        .then((response) => this.calculate(response.data))
        .catch(function (error) {
          console.log(error);
        });
    },
    calculate(data) {
      data.forEach((cg) => {
        cg.language = this.getLanguage(cg.language);
        cg.gender = this.getGender(cg.gender);
        cg.meetingDay = this.getMeetingDay(cg.meetingDay);
      });
      this.coreGroups = data;
      console.log('core groups data')
      console.log(this.coreGroups)
    },
    getLanguage(value) {

      let language = null;
      this.languageOptions.forEach((l) => {
        if (l.value === value) {
          language = l;
        }
      });
      return language;
    },
    getGender(value) {

      let gender = null;
      this.genderOptions.forEach((g) => {
        if (g.value === value) {
          gender = g;
        }
      });
      return gender;
    },
    getMeetingDay(value) {

      let meetingDay = null;
      this.meetingDayOptions.forEach((md) => {
        if (md.value === value) {
          meetingDay = md;
        }
      });
      return meetingDay;
    },
    getLocalTime(value) {
      return moment.utc(value.meetingTime, "hh:mm").local().format("LT");
    },
    getDate(value) {
      if (!value) return null;
      return moment(value).format("DD-MM-YYYY");
    },
    clearFilters() {
      this.disabled = true;
      this.search = "";
      this.meetingDayFilter = this.meetingDayOptions[0];
      this.meetingTimeFilter = this.meetingTimeOptions[0];
      this.genderFilter = this.genderOptions[0];
      this.languageFilter = this.languageOptions[0];
      //this.groupTypeFilter = this.groupTypeOptions[0];
    },
    contactUs(item) {
      this.joinIndex = this.coreGroups.indexOf(item);
      this.joinItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.joinIndex = -1;
      });
      this.validateRecaptcha = false;
    },
    sendEmail() {
      if (this.$refs.form.validate()) {
        this.$recaptchaLoaded();
        this.$recaptcha("login").then((token) => {
          axios
            .post("/api/cbsigroup/sendMail", {
              first_name: this.joinItem.firstName,
              last_name: this.joinItem.lastName,
              contact: this.joinItem.contact,
              email: this.joinItem.email,
              course: this.joinItem.course,
              message: this.joinItem.message,
              entryId: this.joinItem.entryId,
              token: token,
            })
            .then(() => {
              this.dialog = false;
              this.$nextTick(() => {
                this.joinIndex = -1;
              });
              this.$refs.form.reset()
              this.$refs.form.resetValidation()
              this.validateRecaptcha = false;
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      }
    },
    validate: function (response) {
      if (response) this.validateRecaptcha = true;
    },
    invalidate() {
      this.validateRecaptcha = false;
    },
    calculateOffset(value) {
      let offset = -(new Date().getTimezoneOffset() / 60);
      let offsetValue = parseInt(value.substring(0, 2)) + parseInt(offset);
      let minutes = value.substring(value.lastIndexOf(":"), value.length);
      if (offsetValue > 23) {
        let diff = -(23 - offsetValue);
        offsetValue = -1 + diff;
      } else if (offsetValue < 0) {
        offsetValue = 24 + offsetValue;
      }
      if (offsetValue.toString().length === 1) {
        offsetValue = "0" + offsetValue + ":" + minutes;
      } else {
        offsetValue = offsetValue.toString() + ":" + minutes;
      }
      return offsetValue;
    },
  },
};
</script>
