<template>
  <div class="pa-2">
    <v-data-table
      :headers="headers"
      :items="filteredCoreGroups"
      :items-per-page="10"
      item-key="name"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-row class="pa-2">
          <v-col cols="6">
            <v-combobox
              v-model="meetingDayFilter"
              :items="meetingDayOptions"
              label="Meeting Day"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="meetingTimeFilter"
              :items="meetingTimeOptions"
              label="Meeting Time (UTC)"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="genderFilter"
              :items="genderOptions"
              label="Gender"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="languageFilter"
              :items="languageOptions"
              label="Language"
              outlined
              dense
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="pa-2">
          <v-col cols="12">
            <v-text-field v-model="search" label="Keyword Search">
            </v-text-field>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-row class="justify-space-between mx-2 mb-2">
              <v-btn color="primary" v-bind="attrs" v-on="on">
                New Item
                <v-icon small color="white" class="mx-1">
                  mdi-plus
                </v-icon></v-btn
              >
              <v-btn
                depressed
                color="primary"
                @click="clearFilters()"
                :disabled="disabledButton"
              >
                Clear Filters
                <v-icon small color="white" class="mx-1">
                  mdi-filter-remove
                </v-icon>
              </v-btn>
            </v-row>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mx-2">{{ editedItem.course }} Details</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.course"
                      label="Course"
                      :rules="[(v) => !!v || 'Item is required']"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-2">
                    <v-combobox
                      v-model="editedItem.language"
                      :items="languageOptions"
                      label="Language"
                      :rules="[(v) => !!v || 'Item is required']"
                      required
                      outlined
                      dense
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-2">
                    <v-combobox
                      v-model="editedItem.meetingDay"
                      :items="meetingDayOptions"
                      label="Meeting Day"
                      :rules="[(v) => !!v || 'Item is required']"
                      required
                      outlined
                      dense
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-dialog
                      ref="dialog"
                      v-model="modal2"
                      :return-value.sync="editedItem.meetingTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.meetingTimeFormatted"
                          label="Meeting Time (UTC)"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          :rules="[(v) => !!v || 'Item is required']"
                          required
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modal2"
                        v-model="editedItem.meetingTime"
                        full-width
                        format="ampm"
                        ampm-in-title
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(editedItem.meetingTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="editedItem.startDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.startDateFormatted"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          :rules="[(v) => !!v || 'Item is required']"
                          required
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.startDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(editedItem.startDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="editedItem.endDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.endDateFormatted"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          :rules="[(v) => !!v || 'Item is required']"
                          required
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.endDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(editedItem.endDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <a
                      rel="noopener noreferrer"
                      href="https://www.thetimezoneconverter.com/"
                      target="_blank"
                    >
                      Convert time to UTC calculator
                    </a>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-2">
                    <v-combobox
                      v-model="editedItem.gender"
                      :items="genderOptions"
                      label="Gender"
                      :rules="[(v) => !!v || 'Item is required']"
                      required
                      outlined
                      dense
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.contact"
                      label="Contact"
                      :rules="[(v) => !!v || 'Item is required']"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-2">
                    <v-text-field
                        v-model="editedItem.cc"
                        label="Cc"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:item.meetingTime="{ item }">
        {{ getLocalTime(item) }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ getDate(item.startDate) }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ getDate(item.endDate) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "GroupFinderAdmin",

  data() {
    return {
      headers: [
        { text: "Course", value: "course" },
        { text: "Language", value: "language.text" },
        { text: "Gender", value: "gender.text" },
        { text: "Meeting Day", value: "meetingDay.text" },
        { text: "Meeting Time", value: "meetingTime" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Contact", value: "contact" },
        { text: "Cc", value: "cc" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      coreGroups: [],
      dialog: false,
      dialogDelete: false,
      menu: false,
      menu2: false,
      modal2: false,
      search: "",
      editedIndex: -1,
      editedItem: {
        entryId: 0,
        course: "",
        language: null,
        gender: null,
        meetingDay: null,
        meetingTime: null,
        meetingTimeFormatted: null,
        startDate: null,
        startDateFormatted: null,
        endDate: null,
        endDateFormatted: null,
        contact: "",
        cc: ""
      },
      defaultItem: {
        entryId: 0,
        course: "",
        language: null,
        gender: null,
        meetingDay: null,
        meetingTime: null,
        startDate: null,
        endDate: null,
        contact: "",
        cc: ""
      },
      meetingDayFilter: { text: "All Days", value: "" },
      meetingDayOptions: [
        { text: "All Days", value: "" },
        { text: "Sunday", value: "Sunday" },
        { text: "Monday", value: "Monday" },
        { text: "Tuesday", value: "Tuesday" },
        { text: "Wednesday", value: "Wednesday" },
        { text: "Thursday", value: "Thursday" },
        { text: "Friday", value: "Friday" },
        { text: "Saturday", value: "Saturday" },
      ],
      meetingTimeFilter: { text: "All Times", value: "" },
      meetingTimeOptions: [
        { text: "All Times", value: "" },
        { text: "Morning (6 am to noon)", value: "06:00-12:00" },
        { text: "Afternoon (noon to 6 pm)", value: "12:00-18:00" },
        { text: "Evening (6 pm to Midnight)", value: "18:00-24:00" },
        { text: "Night (Minnight to 6 am)", value: "00:00-06:00" },
      ],
      genderFilter: { text: "All", value: "" },
      genderOptions: [
        { text: "All", value: ""},
        {
          text: "Mixed - both men and women",
          value: "Mixed - both men and women",
        },
        { text: "Women", value: "Women" },
        { text: "Men", value: "Men" },
      ],
      languageFilter: { text: "All", value: "" },
      languageOptions: [
        { text: "All", value: "" },
        {
          text: "American Sign Language (ASL)",
          value: "American Sign Language (ASL)",
        },
        { text: "Arabic", value: "Arabic" },
        { text: "Cantonese", value: "Cantonese" },
        { text: "Dutch", value: "Dutch" },
        { text: "English", value: "English" },
        { text: "Filipino", value: "Filipino" },
        { text: "German", value: "German" },
        { text: "Hindi", value: "Hindi" },
        { text: "Indonesian", value: "Indonesian" },
        { text: "Japanese", value: "Japanese" },
        { text: "Korean", value: "Korean" },
        { text: "Mandarin", value: "Mandarin" },
        { text: "Portuguese", value: "Portuguese" },
        { text: "Romanian", value: "Romanian" },
        { text: "Russian", value: "Russian" },
        { text: "Spanish", value: "Spanish" },
        { text: "Tamil", value: "Tamil" },
        { text: "Thai", value: "Thai" },
        { text: "Finnish", value: "Finnish" }
      ],
      valid: false,
    };
  },
  computed: {
    filteredCoreGroups() {
      const meetingDaySearch = this.meetingDayFilter.value.trim();
      const meetingTimeSearch = this.meetingTimeFilter.value.trim();
      const genderSearch = this.genderFilter.value.trim();
      const languageSearch = this.languageFilter.value.trim();
      let filteredCoreGroups = this.coreGroups;

      if (meetingDaySearch !== "") {
        filteredCoreGroups = filteredCoreGroups.filter(
          (cg) => cg.meetingDay.value.indexOf(meetingDaySearch) > -1
        );
      }
      if (meetingTimeSearch !== "") {
        let startTime = meetingTimeSearch.substring(
          0,
          meetingTimeSearch.lastIndexOf("-")
        );
        let endTime = meetingTimeSearch.substring(
          meetingTimeSearch.lastIndexOf("-") + 1,
          meetingTimeSearch.length
        );
        filteredCoreGroups = filteredCoreGroups.filter((cg) => {
          return cg.meetingTime >= startTime && cg.meetingTime <= endTime;
        });
      }
      if (genderSearch !== "") {
        filteredCoreGroups = filteredCoreGroups.filter(
          (cg) => cg.gender.value === genderSearch
        );
      }
      if (languageSearch !== "") {
        filteredCoreGroups = filteredCoreGroups.filter(
          (cg) => cg.language.value === languageSearch
        );
      }

      return filteredCoreGroups;
    },
    disabledButton() {
      const meetingDaySearch = this.meetingDayFilter.value.trim();
      const meetingTimeSearch = this.meetingTimeFilter.value.trim();
      const genderSearch = this.genderFilter.value.trim();
      const languageSearch = this.languageFilter.value.trim();
      if (
        this.search !== "" ||
        meetingDaySearch !== "" ||
        meetingTimeSearch !== "" ||
        genderSearch !== "" ||
        languageSearch !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    editedItem: {
      handler(val) {
        val.startDateFormatted = this.getDate(val.startDate);
        val.endDateFormatted = this.getDate(val.endDate);
        val.meetingTimeFormatted = this.getLocalTime(val);
      },
      deep: true,
    },
  },
  created() {
    this.initialize();

  },
  methods: {
    initialize() {
      axios
        .get("/api/cbsigroup/getAll", {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        })
        .then((response) => this.calculate(response.data))
        .catch(function (error) {
          console.log(error);
        });
    },
    create() {
      axios
        .post("/api/cbsigroup/create", {
          course: this.editedItem.course,
          language: this.editedItem.language.value,
          gender: this.editedItem.gender.value,
          meetingDay: this.editedItem.meetingDay.value,
          meetingTime: this.editedItem.meetingTime,
          startDate: this.editedItem.startDate,
          endDate: this.editedItem.endDate,
          contact: this.editedItem.contact,
          cc: this.editedItem.cc,
        })
        .then(() => this.initialize())
        .catch(function (error) {
          console.log(error);
        });
    },
    update() {
      axios
        .post("/api/cbsigroup/update", {
          entryId: this.editedItem.entryId,
          course: this.editedItem.course,
          language: this.editedItem.language.value,
          gender: this.editedItem.gender.value,
          meetingDay: this.editedItem.meetingDay.value,
          meetingTime: this.editedItem.meetingTime,
          startDate: this.editedItem.startDate,
          endDate: this.editedItem.endDate,
          contact: this.editedItem.contact,
          cc: this.editedItem.cc,
          url: this.editItem.url,
        })
        .then(() => this.initialize())
        .catch(function (error) {
          console.log(error);
        });
    },
    delete() {
      axios
        .get("/api/cbsigroup/deleteById", {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
          params: {
            id: this.editedItem.entryId,
          },
        })
        .then(() => this.initialize())
        .catch(function (error) {
          console.log(error);
        });
    },
    calculate(data) {
      data.forEach((cg) => {
        cg.language = this.getLanguage(cg.language);
        cg.gender = this.getGender(cg.gender);
        cg.meetingDay = this.getMeetingDay(cg.meetingDay);
      });
      this.coreGroups = data;
    },
    getLanguage(value) {
      let language = null;
      this.languageOptions.forEach((l) => {
        if (l.value === value) {
          language = l;
        }
      });
      return language;
    },
    getGender(value) {
      let gender = null;
      this.genderOptions.forEach((g) => {
        if (g.value === value) {
          gender = g;
        }
      });
      return gender;
    },
    getMeetingDay(value) {
      let meetingDay = null;
      this.meetingDayOptions.forEach((md) => {
        if (md.value === value) {
          meetingDay = md;
        }
      });
      return meetingDay;
    },
    getLocalTime(value) {
      return moment.utc(value.meetingTime, "hh:mm").format("LT");
    },
    getDate(value) {
      if (!value) return null;
      return moment(value).format("DD-MM-YYYY");
    },
    clearFilters() {
      this.disabled = true;
      this.search = "";
      this.meetingDayFilter = this.meetingDayOptions[0];
      this.meetingTimeFilter = this.meetingTimeOptions[0];
      this.genderFilter = this.genderOptions[0];
      this.languageFilter = this.languageOptions[0];
    },
    editItem(item) {
      this.editedIndex = this.coreGroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.startDateFormatted = this.getDate(
        this.editedItem.startDate
      );
      this.editedItem.endDateFormatted = this.getDate(this.editedItem.endDate);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.coreGroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.delete();
      this.closeDelete();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.update();
        } else {
          this.create();
        }
        this.close();
      }
    },
    join(item) {
      this.joinIndex = this.coreGroups.indexOf(item);
      this.joinItem = Object.assign({}, item);
      this.dialog = true;
    },
  },
};
</script>
